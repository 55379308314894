import mixpanel from 'mixpanel-browser';
import { TrackerService } from '@/track/tracker.service';
import _ from 'lodash';
import { TrackInformation } from '@/track/track.types';
import { seeqVersion } from '@/services/systemConfiguration.utilities';

interface MixpanelTrackingProps {
  companyName: string;
  serverEmail: string;
  userEmail: string;
  userName: string;
  contractNumber: string;
  language: string;
  darkMode: boolean;
}

export default class MixpanelTrackingService extends TrackerService {
  private static serviceInstance?: MixpanelTrackingService;

  dataToIncludeWithEveryEvent = {
    companyName: '',
    serverEmail: '',
    userName: '',
    userEmail: '',
    contractNumber: '',
    seeqVersion: '',
    language: '',
    darkMode: false,
  };

  constructor({
    companyName,
    serverEmail,
    userName,
    userEmail,
    contractNumber,
    language,
    darkMode,
  }: MixpanelTrackingProps) {
    super();

    if (MixpanelTrackingService.serviceInstance) {
      return MixpanelTrackingService.serviceInstance;
    }

    this.dataToIncludeWithEveryEvent = {
      serverEmail,
      userName,
      userEmail,
      contractNumber,
      companyName,
      seeqVersion: seeqVersion(),
      language,
      darkMode,
    };

    mixpanel.init('40ec369f3886f9d8b7bfee0b048d2494', {
      api_host: 'https://telemetry.seeq.com/mixpanel',
    });

    mixpanel.identify(userEmail);

    mixpanel.people.set(this.dataToIncludeWithEveryEvent);

    MixpanelTrackingService.serviceInstance = this;
  }

  trackEvent(category: string, action: string, information: TrackInformation, uniqueEventId: number): void {
    const trackInfo = { action, uniqueEventId };
    let parsedInformation = information;
    try {
      parsedInformation = JSON.parse(information as string);
    } catch (e) {
      // ignore, strings are perfectly valid;
    }
    if (_.isObject(parsedInformation)) {
      _.assign(trackInfo, parsedInformation, this.dataToIncludeWithEveryEvent);
    } else {
      _.assign(trackInfo, { information }, this.dataToIncludeWithEveryEvent);
    }

    mixpanel.track(category, trackInfo);
  }

  reset() {
    delete MixpanelTrackingService.serviceInstance;
  }
}
